// helper function to check we we need to show the fees in the cart
// list of productIds that needs to include the fees into the price display

const includeFeesInPrice = (productId) => {
  const productIds = [
    'e22fe4f6-4323-409c-92dd-7c08c821261d',
    '0dad57b7-4eb3-471c-afd2-08dcc2a21c47',
    '13509931-13e2-46ee-905d-08dcc1506fac',
    'a4911fd3-f74c-46d4-9f62-4c01edead7fc',
    '374dcca8-6e4f-4a7c-afd1-08dcc2a21c47',
    'b9e7cf2f-5685-4abf-e556-08dbbf5f0502',
    '66cd0a37-37e9-4862-abb2-e7a40b05d99b',
    'a3c362b1-2ec4-4f5b-8048-0c941ededb14',
    'c45e1c36-e744-451c-afd3-08dcc2a21c47',
  ];

  return productIds.includes(productId);
};
